import Axios from "axios";
import api from "./api";

const instance = Axios.create({
    baseURL: api.api,
    timeout: 100000
});

instance.interceptors.request.use(config => {
    config.headers["X-Access-Token"] = localStorage.getItem("token") ? localStorage.getItem("token") : ''
    let lang = localStorage.getItem("lang") ?? "en"
    if (lang === 'en') {
        lang = 'en_US'
    }
    if (lang === 'cn') {
        lang = 'zh_CN'
    }
    if (lang === 'jp') {
        lang = 'ja_JP'
    }
    if (lang === 'ru') {
        lang = 'sv_SE'
    }
    if (lang === 'kor') {
        lang = 'ko_KR'
    }
    if (lang === 'sp') {
        lang = 'es_ES'
    }
    if (lang === 'th') {
        lang = 'th_TH'
    }
    config.headers["language"] = lang
    return config;
}, error => {
    return Promise.reject(error);
})

export default instance;
