<template>
    <div>
        <navBar :title="$t('通知')"></navBar>
        <van-pull-refresh v-model="listLoading" @refresh="onRefresh">

            <van-list :loading="dataLoading" :finished="finished" finished-text="没有更多了" @load="onLoad">

                <div @click="showNotice(item)"
                    class="flex mt-4 items-center justify-between bg-secondary py-4 px-5 rounded-xl"
                    v-for="item in noticeList" :key="item" :title="item">
                    <div class="left flex items-center gap-3" style="width: 99%;">
                        <div class="icon h-11 w-11 rounded-full bg-btn flex items-center justify-center">
                            <svg widths="24" height="24" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="2338">
                                <path
                                    d="M322.37312 204.1344l165.1712-120.32 175.104 36.7104L784.81152 273.92l87.9616 334.7456 8.3968 33.6384a461.0048 461.0048 0 0 1-98.1504 10.496c-249.9072 0-453.376-199.68-460.5952-448.6656z"
                                    fill="#fff" p-id="2339"></path>
                                <path
                                    d="M266.05312 734.3104h1.8944c35.1744 0 63.6928 28.5696 63.6928 63.8976a63.7952 63.7952 0 0 1-67.4304 63.7952H212.70272a136.5504 136.5504 0 0 1-120.32-71.168 131.7376 131.7376 0 0 1 5.12-135.8848l5.12-8.0384a511.0272 511.0272 0 0 0 81.7152-278.1696V364.544a32.4608 32.4608 0 0 1-0.1024-2.7136V351.5904a39.936 39.936 0 0 1 0.3584-4.7616 330.3424 330.3424 0 0 1 102.2976-224.0512A340.6336 340.6336 0 0 1 524.15232 27.2896c88.9344 0 173.2096 33.8944 237.2608 95.488a330.3424 330.3424 0 0 1 102.2976 224.0512 40.2432 40.2432 0 0 1 0.3072 4.7616v8.8064c0.1024 1.536 0.0512 3.072-0.0512 4.5568v3.7376a510.976 510.976 0 0 0 81.664 278.2208l5.12 8.0384c26.8288 41.6256 28.7744 92.416 5.12 135.8848a136.5504 136.5504 0 0 1-120.32 71.168H527.07072A63.7952 63.7952 0 0 1 454.21312 798.72c0-34.9184 27.904-63.2832 62.5152-63.8976v-0.3584h260.7616c22.1696 0 41.5744-11.4688 51.9168-30.6176a55.7056 55.7056 0 0 0-2.1504-57.2416l-4.1984-6.5536a472.2688 472.2688 0 0 1-74.752-267.0592c-2.9696-118.784-103.424-215.3472-224.1536-215.3472s-221.184 96.5632-224.1024 215.3472v10.8544a472.2688 472.2688 0 0 1-74.8032 256.2048l-4.1984 6.5536c-11.264 17.5616-12.0832 38.912-2.2016 57.2416 9.6256 17.8176 27.0336 28.928 47.2064 30.464zM358.31552 921.6h307.2a51.2 51.2 0 0 1 0 102.4H358.31552a51.2 51.2 0 0 1 0-102.4z"
                                    fill="#fff" p-id="2340"></path>
                            </svg>
                        </div>
                        <div class="info flex flex-col items-start gap-1.5" style="width: 90%;">
                            <div class="flex items-center gap-2.5" style="flex-direction: column !important;
    display: flex !important;
    align-items: flex-start !important;">

                                <p class="text-white text-sm font-medium">
                                    <b>{{ item.title }}</b>
                                </p>

                                <p class="text-gray-100 text-xs font-medium" v-html="item.context"
                                    style="margin-left: 0px; !important">
                                </p>

                                <div class="item flex items-center gap-1"><svg width="12" height="12" viewBox="0 0 12 12"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6 1.75C3.6565 1.75 1.75 3.6565 1.75 6C1.75 8.3435 3.6565 10.25 6 10.25C8.3435 10.25 10.25 8.3435 10.25 6C10.25 3.6565 8.3435 1.75 6 1.75ZM6 11C3.243 11 1 8.757 1 6C1 3.243 3.243 1 6 1C8.757 1 11 3.243 11 6C11 8.757 8.757 11 6 11Z"
                                            fill="#8385C9"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.71557 7.84635C7.65007 7.84635 7.58407 7.82935 7.52357 7.79385L5.63857 6.66935C5.52557 6.60135 5.45557 6.47885 5.45557 6.34685V3.92285C5.45557 3.71585 5.62357 3.54785 5.83057 3.54785C6.03807 3.54785 6.20557 3.71585 6.20557 3.92285V6.13385L7.90807 7.14885C8.08557 7.25535 8.14407 7.48535 8.03807 7.66335C7.96757 7.78085 7.84307 7.84635 7.71557 7.84635Z"
                                            fill="#8385C9"></path>
                                    </svg> <span class="text-primary text-xss font-medium">2024-07-01
                                        10:44:09</span></div>
                            </div>
                        </div>
                    </div>
                </div>

            </van-list>

        </van-pull-refresh>


        <!-- 公告 -->
        <van-popup round center z-index="2001" style="background: none;" v-model:show="showNoticePopover">
            <div class="show2" style="text-align: center; padding-top: 10px;">
                <div style="font-size: 20px;text-align: center;padding-top: 1px;">{{ noticeTitle }}</div>
                <div style="font-size: 14px;line-height: 30px;padding: 20px;overflow: auto;height: 330px;"
                    v-html="noticeContent">
                </div>
                <div style="display: flex; justify-content: center; align-items: center;">
                    <div @click="readOK()"
                        style="position: absolute; top: 420px; display: flex; justify-content: center; align-items: center; text-align: center;">
                        {{ $t('确定') }}
                        <div style="width: 16px; height: 16px; margin-left: 10px;">
                            <div
                                style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADjSURBVHgBpZNBCsIwEEVTFaG9hngFewAFz+AJPIO30OLCteDGE6hQ0LXLgAsRLHgIUWzHPyVKGxLa0A+vpDPJZzLtCKGJiAZgDiR4KqSKhcImJAMQgYzsStWewHR4T/W1K5koV1dFv8NhRdk28XXCFjwmwLO0JgUbcDXk8rP8GAq72uADxuBiyI88lPHColsIJuCobVyDOziAfiH+7hhcE3DSYlzFA5w1g4ybKCuadQM9sDLkJPcgFnZxE7dgBqaGfNz4M+Y2WCzIXdG/Drz45P4r+6XLUL1h4tyS9GHSjJzG+Qs8vD9h0NEa+QAAAABJRU5ErkJggg==&quot;); background-position: 0% 0%; background-size: 100% 100%; background-repeat: no-repeat;">
                            </div>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADjSURBVHgBpZNBCsIwEEVTFaG9hngFewAFz+AJPIO30OLCteDGE6hQ0LXLgAsRLHgIUWzHPyVKGxLa0A+vpDPJZzLtCKGJiAZgDiR4KqSKhcImJAMQgYzsStWewHR4T/W1K5koV1dFv8NhRdk28XXCFjwmwLO0JgUbcDXk8rP8GAq72uADxuBiyI88lPHColsIJuCobVyDOziAfiH+7hhcE3DSYlzFA5w1g4ybKCuadQM9sDLkJPcgFnZxE7dgBqaGfNz4M+Y2WCzIXdG/Drz45P4r+6XLUL1h4tyS9GHSjJzG+Qs8vD9h0NEa+QAAAABJRU5ErkJggg=="
                                draggable="false">
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
export default {
    components: { navBar },
    data() {
        return {
            listLoading: false,
            dataLoading: false,
            noticeList: [],
            showNoticePopover: false,
            noticeTitle: '',
            noticeContent: '',
        }
    },
    computed: {},
    watch: {},
    created() {
        //获取公告
        this.findUserNoticeList();
    },
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
    // 方法集合
    methods: {
        readOK() {
            this.showNoticePopover = false;
        },
        showNotice(item) {
            this.showNoticePopover = true;
            this.noticeTitle = item.title;
            this.noticeContent = item.context;
        },
        onRefresh() {
            this.findUserNoticeList();
        },
        findUserNoticeList() {
            //公告
            var address = localStorage.getItem('address');
            this.$request.get("nft/home/findNoticeList?address=" + address, "").then((res) => {
                this.listLoading = false;
                if (res.data.code === 200) {
                    var result = res.data.result
                    if (result) {
                        this.noticeList = result;
                    }
                }
            });
        }
    }
}
</script>

<style >
p {
    margin: 4px;
}
</style>
