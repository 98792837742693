<template>
    <div>
        <navBar :title="$t('活动')"></navBar>

        <van-pull-refresh v-model="listLoading" @refresh="onRefresh">
            <van-list :loading="dataLoading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad">

                <div class="flex mt-4 items-center justify-between bg-secondary py-4 px-5 rounded-xl"
                    v-for="item in activityList" :key="item" :title="item">
                    <div class="left flex items-center gap-3">
                        <div class="icon h-11 w-11 rounded-full bg-btn flex items-center justify-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.8999 2.19992L9.4999 7.79992H7.0999C6.6999 7.79992 6.2999 7.79992 5.8999 7.89992L6.8999 5.49992V5.39992L6.9999 5.29992C6.9999 5.19992 6.9999 5.19992 7.0999 5.09992C8.2999 2.39992 9.5999 1.59992 11.8999 2.19992Z"
                                    fill="white"></path>
                                <path
                                    d="M18.7 8.1C18.1 7.9 17.5 7.8 16.8 7.8H10.5L12.8 2.6V2.5C12.9 2.5 13.1 2.6 13.2 2.7L15.4 3.6C16.6 4.1 17.5 4.6 18 5.3C18.1 5.4 18.2 5.5 18.3 5.7C18.4 5.8 18.5 6 18.5 6.1C18.5 6.2 18.6 6.3 18.6 6.4C18.9 6.9 18.9 7.4 18.7 8.1Z"
                                    fill="white"></path>
                                <path
                                    d="M12.5 17.6999H12.8C13.1 17.6999 13.4 17.3999 13.4 17.0999C13.4 16.6999 13.3 16.5999 13 16.4999L12.6 16.3999V17.6999H12.5Z"
                                    fill="white"></path>
                                <path
                                    d="M18.3002 9.4998C17.9002 9.3998 17.4002 9.2998 16.9002 9.2998H7.1002C6.4002 9.2998 5.8002 9.3998 5.2002 9.6998C3.5002 10.3998 2.2002 12.1998 2.2002 14.1998V16.0998V16.7998C2.5002 19.9998 4.2002 21.6998 7.4002 21.9998H8.1002H15.9002C19.6002 21.9998 21.5002 20.1998 21.7002 16.6998C21.7002 16.4998 21.7002 16.2998 21.7002 16.0998V14.1998C21.8002 11.9998 20.3002 10.0998 18.3002 9.4998ZM13.3002 15.4998C13.8002 15.6998 14.4002 15.9998 14.4002 17.0998C14.4002 17.9998 13.7002 18.6998 12.8002 18.6998H12.5002V18.8998C12.5002 19.1998 12.3002 19.3998 12.0002 19.3998C11.7002 19.3998 11.5002 19.1998 11.5002 18.8998V18.6998H11.4002C10.4002 18.6998 9.6002 17.8998 9.6002 16.8998C9.6002 16.5998 9.8002 16.3998 10.1002 16.3998C10.4002 16.3998 10.6002 16.5998 10.6002 16.8998C10.6002 17.2998 10.9002 17.6998 11.3002 17.6998H11.4002V15.9998L10.6002 15.6998C10.1002 15.4998 9.5002 15.1998 9.5002 14.0998C9.5002 13.1998 10.2002 12.4998 11.1002 12.4998H11.4002V12.2998C11.4002 11.9998 11.6002 11.7998 11.9002 11.7998C12.2002 11.7998 12.4002 11.9998 12.4002 12.2998V12.4998H12.5002C13.5002 12.4998 14.3002 13.2998 14.3002 14.2998C14.3002 14.5998 14.1002 14.7998 13.8002 14.7998C13.5002 14.7998 13.3002 14.5998 13.3002 14.2998C13.3002 13.8998 13.0002 13.4998 12.6002 13.4998H12.5002V15.1998L13.3002 15.4998Z"
                                    fill="white"></path>
                                <path
                                    d="M10.7002 14.1C10.7002 14.5 10.8002 14.6 11.1002 14.7L11.5002 14.8V13.5H11.2002C10.9002 13.5 10.7002 13.8 10.7002 14.1Z"
                                    fill="white"></path>
                            </svg>
                        </div>
                        <div class="info flex flex-col items-start gap-1.5">
                            <p class="text-white text-sm font-medium">{{ item.type == 1 ? $t('质押活动') : $t('奖励活动') }}</p>
                            <p class="text-gray-100 text-xs font-medium">{{ $t('数量') }}: {{ item.usdtNumber }} USDT </p>
                            <div class="flex items-center gap-2.5">
                                <div class="item flex items-center gap-1"><svg width="12" height="12" viewBox="0 0 12 12"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6 1.75C3.6565 1.75 1.75 3.6565 1.75 6C1.75 8.3435 3.6565 10.25 6 10.25C8.3435 10.25 10.25 8.3435 10.25 6C10.25 3.6565 8.3435 1.75 6 1.75ZM6 11C3.243 11 1 8.757 1 6C1 3.243 3.243 1 6 1C8.757 1 11 3.243 11 6C11 8.757 8.757 11 6 11Z"
                                            fill="#8385C9"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.71557 7.84635C7.65007 7.84635 7.58407 7.82935 7.52357 7.79385L5.63857 6.66935C5.52557 6.60135 5.45557 6.47885 5.45557 6.34685V3.92285C5.45557 3.71585 5.62357 3.54785 5.83057 3.54785C6.03807 3.54785 6.20557 3.71585 6.20557 3.92285V6.13385L7.90807 7.14885C8.08557 7.25535 8.14407 7.48535 8.03807 7.66335C7.96757 7.78085 7.84307 7.84635 7.71557 7.84635Z"
                                            fill="#8385C9"></path>
                                    </svg> <span class="text-primary text-xss font-medium">{{ formatDate(item.startTime)
                                    }}-{{ formatDate(item.endTime) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right flex flex-col items-end gap-0.5">
                        <p class="text-base text-white font-medium">
                            <span>{{ $t('收益') }}:{{ item.ethIncome }}
                            </span>
                        </p>
                        <span class="uppercase font-medium text-primary text-xxs">
                            ETH
                        </span>
                        <span class="mt-2 van-tag van-tag--success">{{ item.status == 1 ? $t('chulizhong') : $t('完成')
                        }}</span>
                        <button type="button" class="van-button van-button--primary van-button--mini van-button--disabled"
                            disabled="">
                            <div class="van-button__content">
                                <span class="van-button__text">{{ $t('解押') }}</span>
                            </div>
                        </button>
                    </div>
                </div>

            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
export default {
    components: { navBar },
    data() {
        return {
            listLoading: false,
            dataLoading: false,
            activityList: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        //获取活动
        this.findUserActivityList();
    },
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
    // 方法集合
    methods: {
        formatDate(inputDateStr) {
            const dateObj = new Date(inputDateStr);
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1;
            const day = dateObj.getDate();
            const formattedDate = `${year}/${month}/${day}`;
            return formattedDate;
        },
        onRefresh() {
            this.findUserActivityList();
        },
        findUserActivityList() {
            //活动
            var address = localStorage.getItem('address');
            this.$request.get("nft/home/findActivityList?address=" + address, "").then((res) => {
                this.listLoading = false;
                if (res.data.code === 200) {
                    var result = res.data.result
                    if (result) {
                        this.activityList = result;
                    }
                }
            });
        }
    }
}
</script>

<style >
p {
    margin: 4px;
}
</style>
