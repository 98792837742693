<template>
  <div style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #212244;
    ">
    <van-row align="center">
      <van-col span="6">
        <img src="@/assets/image/mine.png" alt="" style="width: 64px; height: 64px; border-radius: 10px" />
      </van-col>
      <van-col span="18" style="font-size: 16px">
        {{
          address.substring(0, 4) +
          "****" +
          address.substring(address.length - 5)
        }}
      </van-col>
    </van-row>
  </div>
  <!-- <div style="padding: 30px; box-sizing: border-box">
    <van-row style="padding: 15px; border-radius: 10px; background-color: #212244">
      <van-col span="12"> {{ $t("tibiqianbao") }} </van-col>
      <van-col span="12" style="text-align: right">
        {{
          address.substring(0, 4) +
          "****" +
          address.substring(address.length - 5)
        }}
      </van-col>
    </van-row>
  </div> -->
  <div style="width: 100%; padding: 0 30px 30px; box-sizing: border-box;margin-top: 20px;">
    <van-row>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 10px;
        ">
        <van-row>
          <van-col span="24" style="padding: 5px 10px; box-sizing: border-box">
            <van-row>
              <van-col span="24">
                <div style="border-bottom: 1px solid #2c2e55;
    padding: 14px 0;">
                  {{ $t("keyikuang") }}

                  <span style="float:right">
                    ≈
                    <span>{{ parseFloat(usdtMoney).toFixed(2) }}</span>
                    <span style="margin-left:5px;color:#7080b3 !important;">USDT</span>
                  </span>

                </div>


              </van-col>

              <van-col span="24" style="margin-top:15px;"> {{ $t("tibishuliang") }}</van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      ">
                      <span style="margin-left: 5px">USDT</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px"
                        @click="change_price = parseFloat(usdtMoney).toFixed(2)">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="rgba(107,84,238,1)" size="large" @click="auth">{{ $t("tibi") }}</van-button>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="#363762" size="large" @click="goTibi">{{
                  $t("tibijilu")
                }}</van-button>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <div style="
        background: linear-gradient(318.46deg, #6b54eb 32.62%, #d080ff);
        border-radius: 20px;
        padding: 20px 0;
        box-sizing: border-box;
      ">
      <van-row>
        <van-col span="6" style="text-align: center">
          <div><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="44" height="44" rx="22" fill="white"></rect>
              <rect x="9" y="9" width="26" height="26" rx="13" fill="#A680FF"></rect>
              <mask id="mask0_201_1399" maskUnits="userSpaceOnUse" x="9" y="9" width="26" height="26"
                style="mask-type:alpha;">
                <rect x="9" y="9" width="26" height="26" rx="13" fill="#A680FF"></rect>
              </mask>
              <g mask="url(#mask0_201_1399)">
                <g clip-path="url(#clip0_201_1399)">
                  <path d="M31.3032 22.6815L28.6099 24.6886V20.5249L31.3032 22.6815Z" fill="#D68239"></path>
                  <path
                    d="M23.1904 15.9271L24.7821 17.2481H19.2134L20.8051 15.9271C21.5006 15.3576 22.4996 15.3576 23.1904 15.9271Z"
                    fill="#D68239"></path>
                  <path
                    d="M28.6097 20.9917V25.1555L23.1156 29.2539C22.4527 29.7486 21.5425 29.7486 20.8843 29.2539L15.3296 25.1088L15.3436 25.0901V17.1594H28.6143V20.9917H28.6097Z"
                    fill="white"></path>
                  <path d="M15.3435 20.4923V24.6234L15.3295 24.642L12.6968 22.6815L15.3388 20.4923H15.3435Z"
                    fill="#D68239"></path>
                  <path
                    d="M28.6095 24.6887L31.2982 22.6815V33.1329C31.2982 34.1645 30.4627 35 29.4311 35H14.5639C13.5323 35 12.6968 34.1645 12.6968 33.1329V22.6815L15.3248 24.642L20.8795 28.7871C21.5424 29.2819 22.4526 29.2819 23.1108 28.7871L28.6095 24.6887Z"
                    fill="#EEA953"></path>
                  <path
                    d="M21.6266 22.4015H19.1059C18.8492 22.4015 18.6392 22.1914 18.6392 21.9347C18.6392 21.6779 18.8492 21.4679 19.1059 21.4679H21.6266C21.8833 21.4679 22.0934 21.6779 22.0934 21.9347C22.0934 22.1914 21.8833 22.4015 21.6266 22.4015Z"
                    fill="#D68239"></path>
                  <path
                    d="M24.8941 24.7355H19.1059C18.8492 24.7355 18.6392 24.5254 18.6392 24.2687C18.6392 24.0119 18.8492 23.8019 19.1059 23.8019H24.8941C25.1508 23.8019 25.3609 24.0119 25.3609 24.2687C25.3609 24.5254 25.1508 24.7355 24.8941 24.7355Z"
                    fill="#D68239"></path>
                </g>
              </g>
              <defs>
                <lineargradient id="paint0_linear_201_1399" x1="31.4545" y1="12.5455" x2="7.375" y2="30.125"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D18BFF"></stop>
                  <stop offset="0.697917" stop-color="#7273FF"></stop>
                  <stop offset="1" stop-color="#61A5FF"></stop>
                </lineargradient>
                <lineargradient id="paint1_linear_201_1399" x1="31.4545" y1="12.5455" x2="7.375" y2="30.125"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#C163FF"></stop>
                  <stop offset="0.697917" stop-color="#6465FF"></stop>
                  <stop offset="1" stop-color="#3A8EFE"></stop>
                </lineargradient>
                <clippath id="clip0_201_1399">
                  <rect width="19.5" height="19.5" fill="white" transform="translate(12.25 15.5)"></rect>
                </clippath>
              </defs>
            </svg></div>
        </van-col>
        <van-col span="11">
          <van-row>
            <van-col span="24" style=""> {{ $t("yaoqingpengyou") }}</van-col>
            <van-col span="24" style="margin-top: 15px">
              {{ $t("zhuanquyongjin") }}</van-col>
          </van-row>
        </van-col>
        <van-col span="6">
          <van-button color="#ffffff" round style="color: #7080b3;    height: 40px;
    width: 75px;
    margin-top: 5px;
    border-radius: 10px;" @click="this.$router.push('/invite')">{{ $t("yaoqing")
    }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>

  <div style="width: 100%; padding: 0px 30px 30px 30px; box-sizing: border-box">
    <div style="
        background: linear-gradient(318.46deg, #4971ac 32.62%, #3a2bc0);
        border-radius: 20px;
        padding: 20px 0;
        box-sizing: border-box;
      ">
      <van-row>
        <van-col span="6" style="text-align: center">
          <div><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="44" height="44" rx="22" fill="white"></rect>
              <rect x="9" y="9" width="26" height="26" rx="13" fill="#A680FF"></rect>
              <mask id="mask0_201_1399" maskUnits="userSpaceOnUse" x="9" y="9" width="26" height="26"
                style="mask-type:alpha;">
                <rect x="9" y="9" width="26" height="26" rx="13" fill="#A680FF"></rect>
              </mask>
              <g mask="url(#mask0_201_1399)">
                <g clip-path="url(#clip0_201_1399)">
                  <path d="M31.3032 22.6815L28.6099 24.6886V20.5249L31.3032 22.6815Z" fill="#D68239"></path>
                  <path
                    d="M23.1904 15.9271L24.7821 17.2481H19.2134L20.8051 15.9271C21.5006 15.3576 22.4996 15.3576 23.1904 15.9271Z"
                    fill="#D68239"></path>
                  <path
                    d="M28.6097 20.9917V25.1555L23.1156 29.2539C22.4527 29.7486 21.5425 29.7486 20.8843 29.2539L15.3296 25.1088L15.3436 25.0901V17.1594H28.6143V20.9917H28.6097Z"
                    fill="white"></path>
                  <path d="M15.3435 20.4923V24.6234L15.3295 24.642L12.6968 22.6815L15.3388 20.4923H15.3435Z"
                    fill="#D68239"></path>
                  <path
                    d="M28.6095 24.6887L31.2982 22.6815V33.1329C31.2982 34.1645 30.4627 35 29.4311 35H14.5639C13.5323 35 12.6968 34.1645 12.6968 33.1329V22.6815L15.3248 24.642L20.8795 28.7871C21.5424 29.2819 22.4526 29.2819 23.1108 28.7871L28.6095 24.6887Z"
                    fill="#EEA953"></path>
                  <path
                    d="M21.6266 22.4015H19.1059C18.8492 22.4015 18.6392 22.1914 18.6392 21.9347C18.6392 21.6779 18.8492 21.4679 19.1059 21.4679H21.6266C21.8833 21.4679 22.0934 21.6779 22.0934 21.9347C22.0934 22.1914 21.8833 22.4015 21.6266 22.4015Z"
                    fill="#D68239"></path>
                  <path
                    d="M24.8941 24.7355H19.1059C18.8492 24.7355 18.6392 24.5254 18.6392 24.2687C18.6392 24.0119 18.8492 23.8019 19.1059 23.8019H24.8941C25.1508 23.8019 25.3609 24.0119 25.3609 24.2687C25.3609 24.5254 25.1508 24.7355 24.8941 24.7355Z"
                    fill="#D68239"></path>
                </g>
              </g>
              <defs>
                <lineargradient id="paint0_linear_201_1399" x1="31.4545" y1="12.5455" x2="7.375" y2="30.125"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D18BFF"></stop>
                  <stop offset="0.697917" stop-color="#7273FF"></stop>
                  <stop offset="1" stop-color="#61A5FF"></stop>
                </lineargradient>
                <lineargradient id="paint1_linear_201_1399" x1="31.4545" y1="12.5455" x2="7.375" y2="30.125"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#C163FF"></stop>
                  <stop offset="0.697917" stop-color="#6465FF"></stop>
                  <stop offset="1" stop-color="#3A8EFE"></stop>
                </lineargradient>
                <clippath id="clip0_201_1399">
                  <rect width="19.5" height="19.5" fill="white" transform="translate(12.25 15.5)"></rect>
                </clippath>
              </defs>
            </svg></div>
        </van-col>
        <van-col span="11">
          <van-row>
            <van-col span="24" style=""> {{ $t("奖池活动") }}</van-col>
            <van-col span="24" style="margin-top: 15px">
              {{ $t("zhuanquyongjin") }}</van-col>
          </van-row>
        </van-col>
        <van-col span="6">
          <van-button color="#ffffff" round style="color: #7080b3;    height: 40px;
    width: 75px;
    margin-top: 5px;
    border-radius: 10px;" @click="this.$router.push('/activity')">{{ $t("活动")
    }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>

  <div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
    ">
    <van-row>
      <van-col span="12" style="font-size: 1.25rem">
        {{ $t("wodeshouyi") }}</van-col>
      <van-col span="12" style="text-align: right">
        <van-button color="#372d3d" style="color: #f90; padding: 10px 20px" size="small" round @click="goEarning">{{
          $t("chakanquanbu") }}</van-button>
      </van-col>
    </van-row>
    <div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px;text-align: center">
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
          ETH
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3;">
          ETH
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px;text-align: center;font-size: 17px;">
        <van-col span="8">
          <span> {{ ethTotal }}</span>
          <!-- <count-up :startVal="0" :endVal="ethTotal" :duration="4"></count-up> -->
        </van-col>
        <van-col span="8" style="text-align: center">
          <span> {{ usdtTodayTotal }}</span>
          <!-- <count-up :startVal="0" :endVal="usdtTodayTotal" :duration="4"></count-up> -->
        </van-col>
        <van-col span="8">
          <span> {{ ethTodayTotal }}</span>
          <!-- <count-up :startVal="0" :endVal="ethTodayTotal" :duration="4"></count-up> -->
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px; text-align: center;">
        <van-col span="8" style="font-size: 12px;"> {{ $t("quanbushouru") }}</van-col>
        <van-col span="8" style="text-align: center;font-size: 12px;">
          {{ $t("jinrishouyi") }}</van-col>
        <van-col span="8" style="text-align: center;font-size: 12px;">
          {{ $t("jinrishouyi") }}</van-col>
      </van-row>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
      margin-top: 20px;
    ">
    <van-row>
      <van-col span="12" style="font-size: 1.25rem">
        {{ $t("wodetuandui") }}</van-col>
      <van-col span="12" style="text-align: right">
        <van-button color="#372d3d" style="color: #f90; padding: 10px 20px" size="small" round @click="goTeam">
          {{ $t("chakanquanbu") }}</van-button>
      </van-col>
    </van-row>
    <div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px;text-align: center">
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
          {{ $t("renshu") }}
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3;  ">
          ETH
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px;text-align: center;font-size: 17px;">
        <van-col span="8">
          <count-up :startVal="0" :endVal="totalPeople" :duration="4"></count-up>
        </van-col>
        <van-col span="8" style="text-align: center">
          <count-up :startVal="0" :endVal="usdtTodayPeople" :duration="4"></count-up>
        </van-col>
        <van-col span="8">
          <count-up :startVal="0" :endVal="ethTodayPeople" :duration="4"></count-up>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px;text-align: center">
        <van-col span="8" style="font-size: 12px;"> {{ $t("suoyouxiaji") }}</van-col>
        <van-col span="8" style="text-align: center;font-size: 12px;">
          {{ $t("jinrishouyi") }}</van-col>
        <van-col span="8" style="font-size: 12px;">
          {{ $t("jinrishouyi") }}</van-col>
      </van-row>
    </div>
  </div>
  <div style="height: 90px"></div>
</template>

<script>
import CountUp from 'vue-countup-v3'
export default {
  name: "my",
  components: { CountUp },
  data() {
    return {
      change_price: 0,
      usdtMoney: 0,
      address: "",
      ethTotal: 0,
      ethTodayTotal: 0,
      usdtTodayTotal: 0,
      totalPeople: 0,
      usdtTodayPeople: 0,
      ethTodayPeople: 0,
      userUrl: '',
      interval: null,
    };
  },
  methods: {
    getMoney() {
      if (this.address) {
        this.$request.post("nft/home/getUsdtMoney", {}).then((res) => {
          if (res.data.code === 200) {
            this.usdtMoney = res.data.result.usdtMoney;
          }
        });
      }
    },
    async getTrcWallet() {
      this.address = localStorage.getItem("address");
    },
    // 提币操作
    async auth() {
      if (this.change_price == 0) {
        return this.$toast(this.$t("amountError"));
      }
      this.$request
        .post("/nft/scCoinLog/applyWithdraw", {
          amount: this.change_price,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.getMoney();
            this.change_price = 0;
            return this.$toast(this.$t(res.data.message));
          } else {
            return this.$toast(this.$t(res.data.message));
          }
        });
    },
    getCenterInfo() {
      if (this.address) {
        this.$request
          .post("nft/home/getCenterInfo", {
            amount: this.change_price,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.ethTotal = res.data.result.ethTotal;
              this.ethTodayTotal = res.data.result.ethTodayTotal;
              this.usdtTodayTotal = res.data.result.usdtTodayTotal;
              this.totalPeople = res.data.result.totalPeople;
              this.usdtTodayPeople = res.data.result.usdtTodayPeople;
              this.ethTodayPeople = res.data.result.ethTodayPeople;
              this.userUrl = res.data.result.userUrl;
            }
          });
      }
    },

    goEarning() {
      this.$router.push({ path: "/earning" });
    },
    goTeam() {
      this.$router.push({ path: "/team" });
    },
    goTibi() {
      this.$router.push({ path: "/widthw" });
    },
    clearScrollInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // 在离开当前路由时清除定时器
    this.clearScrollInterval();
    next();
  },
  beforeDestroy() {
    // 在 beforeDestroy 钩子中确保也清除定时器
    this.clearScrollInterval();
  },
  mounted() {
    this.getTrcWallet();
    this.getMoney();
    this.getCenterInfo();
    this.interval = setInterval(() => {
      this.getCenterInfo();
    }, 2000);
  },
};
</script>

<style scoped></style>
