import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Change from "@/views/change"
import Service from "@/views/service";
import My from "@/views/my"
import Invite from "@/views/invite"
import Layout from '@/layout/index'
import Notice from '@/views/notice'
import Activity from '@/views/activity'

const routes = [{
  path: '/',
  redirect: '/home',
},
{
  path: '/home',
  component: Layout,
  children: [{
    path: '/home',
    component: HomeView
  }]
},
{
  path: '/about',
  name: 'about',
  component: Layout,
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  children: [{
    path: '/about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }]
},
{
  path: "/change",
  name: "change",
  component: Layout,
  children: [{
    path: '/change',
    component: Change
  }]

},
{
  path: "/service",
  name: "service",
  component: Layout,
  children: [{
    path: "/service",
    component: Service
  }],

},
{
  path: "/my",
  name: "my",
  component: Layout,
  children: [{
    path: "/my",
    component: My
  }]

},
{
  path: "/notice",
  name: "notice",
  children: [{
    path: "/notice",
    component: Notice
  }]

},
{
  path: "/activity",
  name: "activity",
  children: [{
    path: "/activity",
    component: Activity
  }]

},
{
  path: "/invite",
  component: Layout,
  children: [{
    path: "/invite",

    component: Invite
  }]
},
{
  path: "/earning",
  component: () => import( /* webpackChunkName: "about" */ '../views/earning.vue')
},
{
  path: "/team",
  component: () => import( /* webpackChunkName: "about" */ '../views/team.vue')
},
{
  path: "/widthw",
  component: () => import( /* webpackChunkName: "about" */ '../views/widthw.vue')
}
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router