<template>
  <div class="head" :style="{ background: bgColor }">
    <div class="back" @click="back">
      <div class="icon">
        <van-icon name="arrow-left" size="8px" color="#f2f2f2" />
      </div>
    </div>
    <div class="title">{{ title }}</div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "rgb(33, 33, 67)",
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.head {
  /* background: rgb(33, 33, 67); */
  height: 60px;
  padding: 0 30px;
  box-sizing: border-box;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head .back .icon {
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
  box-sizing: border-box;
}

.head .back,
.head .right {
  width: 20%;
}

.head .right {
}
.head .title {
  width: 40%;
  text-align: center;
  font-size: 16px;
}
</style>