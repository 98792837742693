<template>
  <div>
    <div class="head">
      <van-row>
        <van-col span="5">

          <div style="padding-left: 12px;
    color: #fff;
    font-size: 0;
    text-align: left;
    font-weight: 700;
    width: 46px;
    height: 30px;
    margin-top: 20px;"><svg version="1.1" id="SITE_LOGO" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 415.075 282.603"
              enable-background="new 0 0 415.075 282.603" xml:space="preserve" width="100%" height="100%"
              preserveAspectRatio="none" style="width: 100%; height: 100%;">
              <g>
                <g>
                  <polygon fill="#5856d6" points="141.551,282.603 0.25,141.302 141.551,0 193.156,51.605 163.569,81.192 141.551,59.175
                            59.425,141.302 141.551,223.428 223.225,141.755 282.17,141.984">
                  </polygon>
                </g>
                <g>
                  <polygon fill="#5ac8fa" points="273.774,282.603 222.169,230.999 251.757,201.411 273.774,223.428 355.901,141.302
                            273.774,59.175 196.384,136.565 192.33,140.619 133.155,140.619 273.774,0 415.075,141.302">
                  </polygon>
                </g>
              </g>
            </svg></div>

        </van-col>
        <van-col span="3">
          <div style="
display: flex;justify-content: center;align-items: center;width: 100%;height: 30px;border-radius: 17px;background-color: #2c2c53;margin-top: 15px;padding: 4px;
            ">
            <div style="height: 30px;
    line-height: 38px;
    align-items: center;
    justify-content: center;
    background-color: rgba(44, 44, 83, 0.3);
    border-radius: 5px;">
              <img v-if="selectNetWork == 1" :src="require('@/assets/image/trc.png')" alt=""
                style="width: 18px; height: 18px" @click="showPopover = !showPopover" />
              <img v-if="selectNetWork == 2" :src="require('@/assets/image/erc.png')" alt=""
                style="width: 18px; height: 18px" @click="showPopover = !showPopover" />
              <img v-if="selectNetWork == 3" :src="require('@/assets/image/usdc.png')" alt=""
                style="width: 18px; height: 18px" @click="showPopover = !showPopover" />
              <img v-if="selectNetWork == 4" :src="require('@/assets/image/biance.png')" alt=""
                style="width: 18px; height: 18px" @click="showPopover = !showPopover" />
            </div>

            <van-popover :show="showPopover" trigger="click" placement="bottom">
              <div style="
                  background-color: rgb(44, 44, 83);
                  color: rgb(112, 128, 179);
                  padding: 20px 15px;
                  box-sizing: border-box;
                  font-size: 0.75rem;
                  width: 200px;
                ">
                <van-row>
                  <van-col span="24">
                    {{ $t("select_network") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px" align="center">
                  <!-- <van-col span="24">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/trc.png')" alt="" style="width: 18px; height: 18px"
                            @click="flushWallet(1)" />
                          <span style="margin-left: 10px" @click="flushWallet(1)">TRC20</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col> -->
                  <van-col span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/erc.png')" alt="" style="width: 18px; height: 18px"
                            @click="flushWallet(2)" />
                          <span style="margin-left: 10px" @click="flushWalletClear(2)">ETH</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <!-- <van-col span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/usdc.png')" alt="" style="width: 18px; height: 18px"
                            @click="flushWallet(3)" />
                          <span style="margin-left: 10px" @click="flushWallet(3)">USDC</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col> -->
                  <van-col span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/biance.png')" alt="" style="width: 18px; height: 18px"
                            @click="flushWallet(4)" />
                          <span style="margin-left: 10px" @click="flushWalletClear(4)">BSC</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <template #reference>
                <van-icon name="arrow-down" size="15" style="margin-left: 10px; color: #ffffff"
                  @click="showPopover = !showPopover"></van-icon>
              </template>
            </van-popover>
          </div>
        </van-col>

        <van-col span="3" style="margin-left:20px;">
          <div style="
display: flex;justify-content: center;align-items: center;width: 100%;height: 30px;border-radius: 17px;background-color: #2c2c53;margin-top: 15px;padding: 4px;
            ">
            <div style="height: 30px;
    line-height: 38px;
    align-items: center;
    justify-content: center;
    background-color: rgba(44, 44, 83, 0.3);
    border-radius: 5px;">
              <img :src="getLangLogo" alt="" style="width: 18px; height: 18px;" />
            </div>

            <van-popover :show="langShow" trigger="click" placement="bottom">
              <div style="
                  background-color: rgb(44, 44, 83);
                  color: rgb(112, 128, 179);
                  padding: 20px 15px;
                  box-sizing: border-box;
                  font-size: 0.75rem;
                  width: 200px;
                ">
                <van-row>
                  <van-col span="24">
                    {{ $t("select_lang") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px" align="center">
                  <van-col span="24" @click="changeLang('en')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/usd.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">English</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <van-col span="24" style="margin-top: 15px" @click="changeLang('cn')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/china.svg')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">中文繁体</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <!-- <van-col span="24" style="margin-top: 15px" @click="changeLang('jp')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/japan.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Japan</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col> -->
                  <van-col span="24" style="margin-top: 15px" @click="changeLang('sp')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/spain.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Spain</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>

                  <van-col span="24" style="margin-top: 15px" @click="changeLang('pt')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/portugal.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Portugal</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>

                  <van-col span="24" style="margin-top: 15px" @click="changeLang('it')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/italy.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Italy</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>

                  <van-col span="24" style="margin-top: 15px" @click="changeLang('de')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/deutsch.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Deutsch</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>

                  <!-- <van-col span="24" style="margin-top: 15px" @click="changeLang('ru')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/russian.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Russian</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col> -->
                  <!-- <van-col span="24" style="margin-top: 15px" @click="changeLang('kor')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/korean.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Korean</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>

                  </van-col> -->

                  <!-- <van-col span="24" style="margin-top: 15px" @click="changeLang('th')">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img :src="require('@/assets/image/th.png')" alt="" style="width: 18px; height: 18px" />
                          <span style="margin-left: 10px">Thailand</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>

                  </van-col> -->
                </van-row>
              </div>
              <template #reference>
                <van-icon name="arrow-down" size="15" style="margin-left: 10px; color: #ffffff"
                  @click="langShow = !langShow"></van-icon>
              </template>
            </van-popover>
          </div>
        </van-col>

        <van-col span="2" style="align-items: center;margin-left: 30px;margin-top: 9px;" @click="toPage('notice')">
          <van-badge :dot="false">
            <span>
              <a><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 14.873C14.6994 14.873 16.8734 14.2701 17.0834 11.8504C17.0834 9.43229 15.5676 9.58779 15.5676 6.62089C15.5676 4.30341 13.371 1.66663 10 1.66663C6.629 1.66663 4.43239 4.30341 4.43239 6.62089C4.43239 9.58779 2.91669 9.43229 2.91669 11.8504C3.12748 14.2793 5.3015 14.873 10 14.873Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                  </path>
                  <path d="M11.9907 17.381C10.8539 18.6432 9.08058 18.6582 7.93292 17.381" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            </span>
          </van-badge>
        </van-col>

        <van-col span="5" style="margin-left: 18px;">
          <van-button size="small" round color="rgba(88,86,214,.5)"
            style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;background-color: rgba(88,86,214,.5);width:90px;float: right;margin-top: 18px;"
            @click="flushWallet2()">{{
              connectText }}</van-button>
        </van-col>

      </van-row>
    </div>
  </div>
  <router-view />
  <div style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #212244;
    ">
    <van-row style="margin-top: 10px">
      <van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/', '首页')">
        <img :src="getHomeImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/change', '兑换')">
        <img :src="getChangeImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/service', '服务')">
        <img :src="getServiceImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col span="6" style="font-size: 15px; text-align: center" @click="goto('my', '个人中心')">
        <img :src="getMyImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
    </van-row>
    <van-row style="margin-top: 10px; color: #a9abfc">
      <van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/', '首页')">
        {{ $t("shouye") }}
      </van-col>
      <van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/change', '兑换')">
        {{ $t("duihuan") }}
      </van-col>
      <van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/service', '服务')">
        {{ $t("fuwu") }}
      </van-col>
      <van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/my', '个人中心')">
        {{ $t("geren") }}
      </van-col>
    </van-row>
  </div>
  <!-- <div class="fixed" @click="goLink()">
    <img src="@/assets/image/kefu.png" />
  </div> -->
</template>

<script>
import Web3 from "web3";
import browser from "@/config/browser"
export default {
  data() {
    return {
      erc: require("../assets/image/erc.png"),
      lang: require("../assets/image/china.svg"),
      showPopover: false,
      active: 1,
      web3: "",
      tronweb: "",
      balance: "",
      address: "",
      langShow: false,
      selectNetWork: "",
      parentCode: "",
      linkUrl: '',
      connectText: this.$t("connect_wallet")
    };
  },
  computed: {
    getHomeImg() {
      return this.$route.path == "/home"
        ? require("@/assets/image/home_select.png")
        : require("@/assets/image/home.png");
    },
    getChangeImg() {
      return this.$route.path == "/change"
        ? require("@/assets/image/change_select.png")
        : require("@/assets/image/change.png");
    },
    getServiceImg() {
      return this.$route.path == "/service"
        ? require("@/assets/image/service_select.png")
        : require("@/assets/image/service.png");
    },
    getMyImg() {
      return this.$route.path == "/my"
        ? require("@/assets/image/my_select.png")
        : require("@/assets/image/my.png");
    },
    getLangLogo() {
      switch (this.$i18n.locale) {
        case "en":
          return require("@/assets/image/usd.png");
          break;
        case "cn":
          return require("@/assets/image/china.svg");
          break;
        case "jp":
          return require("@/assets/image/japan.png");
          break;
        case "kor":
          return require("@/assets/image/korean.png");
          break;
        case "sp":
          return require("@/assets/image/spain.png");
          break;
        case "ru":
          return require("@/assets/image/russian.png");
          break;
        case "th":
          return require("@/assets/image/th.png");
          break;
        case "de":
          return require("@/assets/image/deutsch.png");
          break;
        case "it":
          return require("@/assets/image/italy.png");
          break;
        case "pt":
          return require("@/assets/image/portugal.png");
          break;
        default:
          return require("@/assets/image/usd.png");
      }
    },
  },
  watch() { },
  created() {
  },
  methods: {
    //fixed
    toPage(path) {
      this.$router.push({ path: path });
    },
    goLink() {
      window.open(this.linkUrl)
    },
    flushWalletClear(netWork) {
      //清理缓存
      localStorage.clear();
      //window.location.reload(true);
      this.showPopover = false;
      this.selectNetWork = netWork;
      this.connectText = this.$t("connect_wallet")
      if (netWork == 1) {
        // trc
        this.getTrcWallet();
      } else if (netWork == 2) {
        // erc
        this.getERCwallet();
      } else if (netWork == 3) {
        // usdc
        this.getUsdcWallet();
      } else if (netWork == 4) {
        // bsc
        this.getBinanceWallet();
      }
    },
    flushWallet(netWork) {
      this.showPopover = false;
      this.selectNetWork = netWork;
      if (netWork == 1) {
        // trc
        this.getTrcWallet();
      } else if (netWork == 2) {
        // erc
        this.getERCwallet();
      } else if (netWork == 3) {
        // usdc
        this.getUsdcWallet();
      } else if (netWork == 4) {
        // bsc
        this.getBinanceWallet();
      }
    },
    flushWallet2() {
      if (this.selectNetWork == 1) {
        // trc
        this.getTrcWallet();
      } else if (this.selectNetWork == 2) {
        // erc
        this.getERCwallet();
      } else if (this.selectNetWork == 3) {
        // usdc
        this.getUsdcWallet();
      } else if (this.selectNetWork == 4) {
        // bsc
        this.getBinanceWallet();
      }
    },
    async getTrcWallet() {
      this.tronweb = window.tronWeb;
      this.address = this.tronweb.defaultAddress.base58;
      if (this.address && this.address != "false") {
        this.register();
      }
    },
    getERCwallet() {
      this.getBinanceWallet();
    },
    async getUsdcWallet() {
      this.getBinanceWallet();
    },
    async getBinanceWallet() {

      //新增连接记录
      this.addAccess("连接钱包");
      if (typeof window.ethereum !== "undefined") {

        let web3js = new Web3(window.ethereum);
        //切换网络 ，安卓未连接钱包的话无效
        // window.ethereum.request({
        //   method: 'wallet_switchEthereumChain',
        //   params: [
        //     {
        //       chainId: web3js.utils.toHex(56),
        //       chainName: 'Binance Smart Chain',
        //       nativeCurrency: {
        //         name: 'Binance Coin',
        //         symbol: 'BNB',
        //         decimals: 18,
        //       },
        //       rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 主链的 RPC 地址
        //       blockExplorerUrls: ['https://bscscan.com/'], // 区块链浏览器地址
        //     },
        //   ],
        // }).catch(function (reason) {
        // });

        ethereum
          .request({
            method: "eth_requestAccounts",
            params: [
            ],
          })
          .then((accounts) => {
            if (accounts.length > 0) {

              window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [
                  {
                    chainId: web3js.utils.toHex(56),
                    chainName: 'Binance Smart Chain',
                    nativeCurrency: {
                      name: 'Binance Coin',
                      symbol: 'BNB',
                      decimals: 18,
                    },
                    rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 主链的 RPC 地址
                    blockExplorerUrls: ['https://bscscan.com/'], // 区块链浏览器地址
                  },
                ],
              }).catch(function (reason) {
              });

              this.address = accounts[0];

              var addressInfo = this.address;
              if (addressInfo) {
                var text = addressInfo.substring(0, 4) + "****" + addressInfo.substring(addressInfo.length - 4)
                this.connectText = text;
              }

              this.register();
            } else {
              alert('No ethereum accounts found');
            }
          })
          .catch(function (reason) { });
      }

      //切换网络
      //       if (typeof window.ethereum !== "undefined") {
      //   window.ethereum.request({
      //     method: 'wallet_addEthereumChain',
      //     params: [
      //       {
      //         chainId: web3js.utils.toHex(56),
      //         chainName: 'Binance Smart Chain',
      //         nativeCurrency: {
      //           name: 'Binance Coin',
      //           symbol: 'BNB',
      //           decimals: 18,
      //         },
      //         rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 主链的 RPC 地址
      //         blockExplorerUrls: ['https://bscscan.com/'], // 区块链浏览器地址
      //       },
      //     ],
      //   }).catch(function (reason) {
      //     alert(JSON.stringify(reason))
      //   });
      // }

    },
    // 注册账号
    async register() {
      var code = this.getCodeParams();
      var userCode = code['c'];

      this.$request
        .post("nft/scUser/userLogin", {
          username: this.address,
          parentCode: userCode,
          networkType: this.selectNetWork,
        })
        .then((res) => {
          if (res.data.code == 200) {
            localStorage.setItem("address", this.address);
            localStorage.setItem("selectNetWork", this.selectNetWork);
            //这是token ，同时设置到期时间30分钟
            this.setTokenAndExpiration(res.data.result.token);
            location.reload();
          } else {
            return this.$toast(res.data.message);
          }
        });
    },
    getCodeParams() {
      // 获取当前 URL
      const currentUrl = window.location.href;
      // 查找参数部分的起始位置
      const startIndex = currentUrl.indexOf('?');
      // 如果找到了参数部分
      const urlParams = {};
      if (startIndex !== -1) {
        // 截取参数部分
        const paramsString = currentUrl.substring(startIndex + 1);
        paramsString.split('&').forEach(param => {
          const [key, value] = param.split('=');
          const index = value.indexOf('#')
          // 如果找到了非数字字符
          if (index !== -1) {
            // 截取从开头到第一个非数字字符之间的部分
            const numPart = value.substring(0, index);
            urlParams[key] = decodeURIComponent(numPart);
          } else {
            urlParams[key] = decodeURIComponent(value);
          }
        });
      }
      return urlParams;
    },
    addAccess(text) {
      //addlog
      var ipinfo = localStorage.getItem('IPINFO');
      if (!ipinfo) {
        return false;
      }
      var ipJson = JSON.parse(ipinfo)
      var params = {
        ipaddress: ipJson.ip,
        location: ipJson.location.country_name + "-" + ipJson.location.province + "-" + ipJson.location.city,
        pageName: text,
        pageUrl: window.location.href,
        device: browser().ios ? 2 : 1
      }
      this.$request.post("nft/home/saveAccess", params).then((res) => {
        if (res.data.code === 200) {
        }
      });
    },
    goto(url, text) {
      this.addAccess(text);
      this.$router.push(url);
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      this.langShow = false;
      location.reload();
    },
    getInfo() {
      this.$request.post("nft/home/getContractInfo", "").then((res) => {
        if (res.data.code === 200) {
          this.linkUrl = res.data.result.systemSetting.linkUrl;
        }
      });
    },
    setTokenAndExpiration(token) {
      //设置token 过期时间为30分钟 达到重新登陆
      const expiration = new Date();
      expiration.setTime(expiration.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds
      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', expiration.toISOString());
    },
    checkTokenExpiration() {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      if (!tokenExpiration) {
        console.log('Token expiration not found.');
        return true; // 没有过期时间，可以认为已过期
      }
      const expirationDate = new Date(tokenExpiration);
      const currentDate = new Date();
      if (currentDate > expirationDate) {
        console.log('Token has expired.');
        return true; // token 已过期
      } else {
        console.log('Token is still valid.');
        return false; // token 仍然有效
      }
    }
  },
  mounted() {
    this.getInfo()
    this.selectNetWork = localStorage.getItem("selectNetWork");
    if (!this.selectNetWork) {
      this.selectNetWork = 4;
      localStorage.setItem("selectNetWork", 4);
    }
    if (this.$route.query.invite_code) {
      this.parentCode = this.$route.query.invite_code;
    }


    //切换网络
    if (browser().ios) {
      if (typeof window.ethereum !== "undefined") {
        //切换网络 ，安卓未连接钱包的话无效
        let web3js = new Web3(window.ethereum);
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: web3js.utils.toHex(56),
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 主链的 RPC 地址
              blockExplorerUrls: ['https://bscscan.com/'], // 区块链浏览器地址
            },
          ],
        }).catch(function (reason) {
        });
      }
    }

    //检查token是否到期 ，到期直接重新获取
    if (this.checkTokenExpiration()) {
      //校验如果是苹果才发起自动授权
      if (browser().ios) {
        this.flushWallet(this.selectNetWork);
      }
    }

    var address = localStorage.getItem("address");
    if (address) {
      var text = address.substring(0, 4) + "****" + address.substring(address.length - 4)
      this.connectText = text;
    }

  },
};
</script>

<style>
.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: rgba(33, 34, 68, 1);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(33, 34, 68, 1);
}

.fixed {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(208, 211, 16, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  right: 20px;
  position: fixed;
}

.fixed img {
  width: 36px;
  height: 36px;
}
</style>